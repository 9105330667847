<template>
    <camera-layout @touchmove="touchMove" @mouseup="mouseUp" @scroll.prevent @scroll.stop @wheel.prevent @mousedown="mouseDown">
        <div class="photo-container d-flex align-center" v-if="isPhoto">
            <img ref="myImage" alt="myImage" :src="imageUrl"/>
        </div>
        <div class="controlAction-container">
            <div class="controlAction-wrapper">
                <gradient-button block @click="handleRedo">{{ $t('dialogs.redo') }}</gradient-button>
                <gradient-button block @click="handleSetImage">{{ $t('dialogs.set_image') }}</gradient-button>
            </div>
        </div>
    </camera-layout>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
import CameraLayout from "@/layouts/app/postingLayout/CameraLayout"
import GradientButton from "@/components/app/button/GradientButton"

export default {
    name: "ViewResult",
    data: () => ({
        width: 0,
        height: 0,
        canvas: '',
        canvasDrawing: '',
        isDrawing: false,
        indexStoryItem: 0,
        mouseDraw: false,
        photo: false,
        loadImage: false,
        stickerSelected: false,
        imageProps: [],
        dataUrl: '',
        isStickers: false,
        stickerIndex: 0,
    }),
    components: {
        CameraLayout,
        GradientButton,
    },
    computed: {
        isPhoto() {
            return this.type === 'photo'
        },
        isVideo() {
            return this.type === 'video'
        },
        ...mapState({
            type: state => state.story.type,
            imageUrl: state => state.story.imageDataUrl,
        })
    },
    methods: {
        send() {
            //this.$refs.canvas.toBlob(blob => this.$store.commit('setOverlay',  blob))
            this.$store.commit('setOverlayDataUrl', this.$refs.editor.dataUrl)
            this.setStep(2)
            //send something
        },
        touchMove() {
            if (!this.isDrawing)
                return false;
        },
        mouseDown() {
            if (!this.isDrawing) {
                return false;
            }
            // this.isStickers = false
            this.mouseDraw = true
        },
        mouseUp() {
            if (!this.isDrawing) {
                return false;
            }
            this.mouseDraw = false
        },
        handleSelectSticker($event, sticker) {
            this.imageProps.push({
                url: sticker,
                key: this.stickerIndex,
            })
            this.stickerIndex++
        },
        handleDeleteSticker() {
            this.$refs.editor.removeSelectedImage()
        },
        handleStickerSelected() {
            this.stickerSelected = true
        },
        handleStickerNotSelected() {
            this.stickerSelected = false
        },
        handleUpdateImageProps(imageProps) {
            this.imageProps = imageProps
        },
        handleStopDrawing() {
            this.isDrawing = false
        },
        handleToggleDrawing() {
            this.isDrawing = !this.isDrawing
        },
        handleCloseStickers() {
            this.isStickers = false
        },
        handleOpenStickers() {
            this.isStickers = true
        },
        handleToggleStickers() {
            this.isStickers = !this.isStickers
        },
        handleDontRefreshPage(event) {
            if (this.isDrawing) {
                event.returnValue = 1
            }
        },
        handleRedo() {
            this.setStep(0)
        },
        handleSetImage() {
            this.$emit('set-image')
            this.setStep(-1)
        },
        ...mapMutations(['hideResultScreen', 'setStep', 'toggleMute', 'setFileResolution'])
    },
    mounted() {
        this.canvas = this.$refs.canvas
        this.canvasDrawing = this.$refs.canvasDrawing
        this.photo = this.$refs.myImage || false;
        if (this.photo) {
            this.photo.src = this.imageUrl
            this.photo.onload = (img) => {
                const imgWidth = img.target.naturalWidth
                const imgHeight = img.target.naturalHeight

                this.height = window.innerHeight
                if (window.innerWidth > imgWidth) {
                    this.width = window.innerWidth
                    if (window.innerHeight > imgHeight) {
                        const ratio = this.height / imgHeight
                        this.width = imgWidth * ratio
                    }
                } else {
                    this.width = window.innerWidth
                    const ratio = this.width / imgWidth
                    this.height = imgHeight * ratio
                }

                this.loadImage = img
                this.setFileResolution({
                    width: img.target.naturalWidth,
                    height: img.target.naturalHeight,
                })
            }
        }
    }
}
</script>

<style scoped lang="scss">
.photo-container {
    justify-content: center;
    align-items: center;
    background-position: center;
    background-size: cover;

    & img {
        object-fit: cover;
        height: 100vh;
        width: auto;
    }
}

.controlAction {
    &-container {
        position: fixed;
        bottom: 3em;
        padding: 0 3em;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
    }

    &-wrapper {
        max-width: 345px;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            text-transform: capitalize;
            width: 50% !important;
            margin: 0 15px;
            min-width: initial !important;
        }
    }
}
</style>
