<template>
    <div v-if="!isFilterPage" class="box-style">
        <v-card
            v-for="parent in filteredUserCategories" :key="parent.id"
            color="#000"
        >
            <v-card-title class="text-h5">
                {{ parent.title }}
            </v-card-title>

            <ul v-if="parent.sub_categories && parent.sub_categories.length">
                <v-chip-group
                    v-model="selectedSubCategoryIds[parent.id]"
                    column
                    @change="categorySelected"
                >
                    <v-chip
                        v-for="child in parent.sub_categories"
                        :key="child.id"
                        :value="child.id"
                        color="#000"
                        active-class="is-selected"
                        style="border: 1px solid #ededed"
                    >
                    <template v-if="parent.title == 'Hair Color'">
                        <template v-if="child.color_code === 'colorful'">
                            <span class="color-box" :style="{ background: 'linear-gradient(to right, #FF0000, #FFFF00)' }"></span>
                        </template>
                        <template v-else>
                            <span
                                class="color-box"
                                :style="{ backgroundColor: child.color_code ? child.color_code : child.title }"
                            ></span>
                        </template>
                    </template>
                        {{ child.title }}
                    </v-chip>
                </v-chip-group>
            </ul>
        </v-card>
    </div>

    <div v-else class="filter-box-style">
        <v-card
            v-for="parent in filteredUserCategories" :key="parent.id"
            color="transparent"
            outlined
        >
            <v-card-title class="text-h5">
                {{ parent.title }}
            </v-card-title>

            <ul v-if="parent.sub_categories && parent.sub_categories.length">
                <v-chip-group
                    v-model="filterSelectedSubCategoryIds"
                    column
                    multiple
                    @change="filterCategorySelected"
                >
                    <v-chip
                        v-for="child in parent.sub_categories"
                        :key="child.id"
                        :value="child.id"
                        active-class="is-filter-selected"
                        style="border: 1px solid #ededed"
                        class="filter-select-item"
                    >
                    <template v-if="parent.title == 'Hair Color'">
                        <template v-if="child.color_code === 'colorful'">
                            <span class="color-box" :style="{ background: 'linear-gradient(to right, #FF0000, #FFFF00)' }"></span>
                        </template>
                        <template v-else>
                            <span
                                class="color-box"
                                :style="{ backgroundColor: child.color_code ? child.color_code : child.title }"
                            ></span>
                        </template>
                    </template>
                        {{ child.title }}
                    </v-chip>
                </v-chip-group>
            </ul>
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {    
    name: "UserCategories",

    data() {
        return {
            selectedSubCategoryIds: {},
            filterSelectedSubCategoryIds: []
        }
    },

    props: {
        userGender: {
            type: String,
        },
        initialSelectedIds: {
            type: Object,
            default: () => ({})
        },
        isFilterPage: {
            type: Boolean,
            default: false,
        },
        initialSelectedFilterIds: {
            type: Array,
            default: () => []
        }
    },

    computed: {
        ...mapState({
            user_categories: (state) => state.user.user.userCategories,
        }),
        filteredUserCategories() {
            if (this.user_categories?.length) {
                if (this.userGender) {                    
                    const filters = {
                        male: item => item.for_male === 1,
                        female: item => item.for_female === 1,
                        transgender: item => item.for_transgender === 1,
                    };
    
                    return this.user_categories.filter(filters[this.userGender]);
                } else {
                    return this.user_categories;
                }
            }
        },
    },

    methods: {
        ...mapActions([ "fetchUserCategories" ]),

        categorySelected() {
            this.$emit('update:selectedCategoryIds', this.selectedSubCategoryIds);
        },
        initializeSelectedSubCategoryIds() {
            this.selectedSubCategoryIds = { ...this.initialSelectedIds };
        },
        initializeSelectedFilterIds() {
            this.filterSelectedSubCategoryIds = this.initialSelectedFilterIds;
        },
        filterCategorySelected() {
            this.$emit('update:selectedCategoryIds', this.filterSelectedSubCategoryIds);
        },
    },
    mounted() {
        this.fetchUserCategories();
    },

    watch: {
        initialSelectedIds: {
            handler() {
                this.initializeSelectedSubCategoryIds();
            },
            immediate: true,
            deep: true,
        },

        initialSelectedFilterIds: {
            handler() {
                this.initializeSelectedFilterIds();
            },
            immediate: true,
            deep: true,
        },
  },
}
</script>

<style lang="scss">
    @import "@/sass/modules/_variables";

    /* Small color box to represent the color in the chip */
    .color-box {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-right: 8px;
    }

    .box-style .v-card { box-shadow: none !important; }
    .box-style .v-card ul { padding: 0px 16px 16px; }
    .is-selected {
        box-shadow: 0px 1px 10px 0px #fe6c11;
        background: $accent-background;
        border: 1px solid #fe4811 !important;
    }
    .filter-select-item {
        background-color: transparent !important;
    }
    .is-filter-selected {
        box-shadow: 0px 1px 10px 0px #fe6c11;
        background-color: white !important;
        border: 1px solid #FE4811 !important;
        color:#FD5B13 !important;
    }
    .box-style .v-card .v-card__title.text-h5 { font-size: 1.2rem !important; padding-bottom: 5px; }

    .box-style .v-card .v-slide-group__content .v-chip:before, .filter-box-style .v-card .v-slide-group__content .v-chip:before { opacity: 0 !important; }
</style>